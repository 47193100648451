import styles from './styles.module.css';
import Image from "@/shared/components/Image/new"; //esto viene de destacada, importar img
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'


const VideoNota = (props) => {
    
    const {
        title,
        subtitle,
        text,
        url,
        url_video,
        texto_cabezal,
        url_cabezal,
        url_link_cabezal,
        isPreview,
        summary, 
        image, 
        author,
        showAuthor = true
        } = props;
    let authorsList = []
	if (showAuthor && (author != undefined && author?.length)) {
		const authorName = formatAuthorNames(author[0].name);
		author.length > 1 ? author.slice(0, 2).map(element => {

			const elementName = formatAuthorNames(element.name);
			return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)

		}) 
		
		: authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
	}
	const parsedAuthors = authorsList.join(' y ');   
    let urlIframe;
    if (url_video.indexOf('youtube') != -1) {
        let regexYoutube = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|(?:v|l(?:ive)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
        const idVideo = regexYoutube.exec(url_video)
        urlIframe =  `https://www.youtube.com/embed/${idVideo[1]}?autoplay=1`;
    } 
    if (url_video.indexOf('youtu') != -1) {
        let regexYoutube = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|(?:v|l(?:ive)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
        const idVideo = regexYoutube.exec(url_video)
        urlIframe =  `https://www.youtube.com/embed/${idVideo[1]}?&autoplay=1`;
    } 
    if (url_video.indexOf('facebook') != -1) {
        urlIframe = "https://www.facebook.com/plugins/video.php?height=314&href=" + encodeURI(url_video) + "&show_text=false&t=0";
    }
    if (url_video.indexOf('livestream.com') != -1) {
        urlIframe = url_video;
     }
     if (url_video.indexOf('jwplayer') != -1) {
        urlIframe = url_video;
     }
     if (url_video.indexOf('habilitado-ellitoral') != -1) {
        urlIframe = url_video;
     } 
     //urlIframe = "https://cdn.jwplayer.com/players/Gr7muSvd-4ccGDP0t.html";
     /*<iframe width="477" height="318" src="https://www.youtube.com/embed/Lr85vrP-J5M" title="María y Paula Marull: “Escribimos historias porque la vida no alcanza”" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>*/
     let cabezal = "";
     if(url_cabezal != "" ) cabezal = <Image	src={url_cabezal} height='25' />;
     if (texto_cabezal != "" && url_cabezal=="") cabezal = <div className={styles['texto-video-nota']}>{texto_cabezal}</div>;
       
     return (
       
          <div className={isPreview ? styles['preview-containter-video-nota'] : styles['container-video-nota']}> 
         
         <div className={styles['center-video-nota']} >
         <a target='_blank' rel='nofollow' href={url_link_cabezal != undefined && url_link_cabezal?.length ? url_link_cabezal:""} >
         {cabezal}
         </a>
        <a href={url} >
					
                    <h1>
                                        <strong>
                                            {subtitle}{" "}
                                        </strong>
                                        {title}
                    </h1>   </a> </div>               
                    <div className={styles['caja2']}>  <a href={url} >	{summary != "" && <div className={styles["bajada-nota-video"]} dangerouslySetInnerHTML={{ __html: summary }} />}</a>
					

{parsedAuthors && <div className={styles["author"]} dangerouslySetInnerHTML={{ __html: 'Por ' + parsedAuthors }} />}
<div className={styles['compartir-video-nota']} ><a href={'https://api.whatsapp.com/send?text=Accede%20a%20esta%20noticia%20de%20El%20Litoral:%20'+url+'%3Futm_medium%3Dwhatsapp%20-%20Envianos%20tu%20noticia%20a:%20%2B5493426305344'} ><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-whatsapp___661fc44e3ef70b001c44f211.jpg" ></img></a>

<a href={`http://twitter.com/share?url=https://www.ellitoral.com${url}`} target="_blank" rel='noreferrer noopener'><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-x___661fc0d43ef70b001c44f210.jpg" ></img></a>

<a href={`https://www.facebook.com/sharer.php?u=https://www.ellitoral.com${url}`} target="_blank" rel='noreferrer noopener'><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-facebook___661fc0d33ef70b001c44f20f.jpg" ></img></a>

</div>
</div>

         
          <div className={styles['embebed-video-nota']}>
              <iframe 
                  src={urlIframe}
                  width="100%"
                  scrolling="no" 
                  frameBorder="0" 
                  loading="lazy"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media ; picture-in-picture; web-share;"
                  title={title}
              />
          </div>             
      </div>
     )
}
export default VideoNota;
